import React, { useState } from 'react';
import styled from 'styled-components';
import { m, AnimatePresence } from 'framer-motion';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '../../../styles/atoms/icons';
import { atMinWidth, breakpoints } from '../../../styles/atoms/breakpoints';
import { isUserAgentMobile } from '../../../utils/getUserAgent';
import PersistLink from '../../PersistLink/PersistLink';

const SolutionsV2Card = ({ background, heading, link, subhead, ...rest }) => {
  const [animate, setAnimate] = useState(false);
  const isXS = useMediaQuery(
    `@media (min-width:0px) and (max-width:${breakpoints.xs})`
  );
  const isSM = useMediaQuery(
    `@media (min-width:${breakpoints.xs}) and (max-width:${breakpoints.sm})`
  );
  const isMD = useMediaQuery(
    `@media (min-width:${breakpoints.sm}) and (max-width:${breakpoints.md})`
  );
  const isLG = useMediaQuery(
    `@media (min-width:${breakpoints.md}) and (max-width:${breakpoints.lg})`
  );
  const isDesktop = !(isUserAgentMobile() || isSM || isXS);

  function manageInitialHeight() {
    if (isSM) return 43;
    if (isMD) return 43;
    if (isMD || isLG) return 60;
    if (isXS) return 40;
    return 66;
  }

  function manageArrowSize() {
    if (isXS || isSM) return 24;
    return 28;
  }

  return (
    <AnimatePresence>
      <StyledCard
        to={link ? link.url : ''}
        as={PersistLink}
        background={background}
        whileHover="hover"
        onMouseOver={isDesktop ? () => setAnimate(true) : null}
        onMouseOut={isDesktop ? () => setAnimate(false) : null}
      >
        <HoverCard
          animate={animate ? 'show' : 'hide'}
          initial={{ height: manageInitialHeight(), bottom: 0 }}
          variants={{
            show: {
              height: isMD ? 260 : 329,
              transition: { duration: 0.4, ease: 'easeIn' },
            },
            hide: { height: manageInitialHeight(), bottom: 0 },
          }}
        >
          <SubheadAnimation
            animate={animate ? 'show' : 'hide'}
            initial={{ opacity: 0 }}
            variants={{
              show: {
                opacity: 1,
                transition: { duration: 0.3, ease: 'easeIn', delay: 0.4 },
              },
              hide: { opacity: 0 },
            }}
            dangerouslySetInnerHTML={{ __html: subhead }}
          />
          <div className="footer">
            <h3>{heading}</h3>
            <AnimatedIconContainer
              animate={animate ? 'show' : 'hide'}
              initial={{ x: 0, bottom: 0 }}
              variants={{
                show: {
                  x: 10,
                  transition: { duration: 0.5, ease: 'easeInOut' },
                },
                hide: { x: 0 },
              }}
            >
              <Icon id="FiArrowRight" size={manageArrowSize()} />
            </AnimatedIconContainer>
          </div>
        </HoverCard>
      </StyledCard>
    </AnimatePresence>
  );
};

export default SolutionsV2Card;

const StyledCard = styled(m.div)`
  background-image: url(${props => props.background});
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 130px;
  display: flex;
  align-items:flex-end;
  justify-content: center;
  grid-column: span 1;

  ${atMinWidth.sm`
    height: 150px;
  `}
  ${atMinWidth.md`
    height: 260px;
  `}

  ${atMinWidth.lg`
    height: 260px;
  `}
  ${atMinWidth.xl`
    height: 329px;
  `}

  border-radius: 11.27px;
  overflow: hidden;
`;

const SubheadAnimation = styled(m.div)`
  opacity: 0;

  p {
    color: white;
    font-family: CircularXX;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.27px;
    pointer-events: none;
  }
`;

const HoverCard = styled(m.div)`
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: relative;

  ${atMinWidth.sm`
    height: 43px;
    padding: 16px 24px;
  `}

  ${atMinWidth.md`
    height: 60px;
    padding: 16px 24px;
  `}

  ${atMinWidth.lg`
    height: 66px;
    padding: 16px 24px;
  `}

  .footer {
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    pointer-events: none;

    ${atMinWidth.sm`
      height: 43px
    `}

    ${atMinWidth.md`
      height: 60px;
    `}

    ${atMinWidth.lg`
      height: 66px;
      padding: 0px 24px;
    `}

    ${atMinWidth.xl`
      padding: 16px 24px;
    `}
  
    svg {
      color: #fff;
    }

    h3 {
      color: var(--Color-Gray-white, #fff);
      font-family: CircularXX;
      font-size: 14.4px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 33.756px */
      letter-spacing: -0.3px;
      margin-bottom: 0;

      ${atMinWidth.sm`
        font-size: 18px;
      `}
      ${atMinWidth.md`
        font-size: 28.13px;
      `}
      ${atMinWidth.lg`
        font-size: 28.13px;
      `}
    }
  }
`;

const AnimatedIconContainer = styled(m.div)`
  color: #fff;
`;
